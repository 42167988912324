@import "../config/";

@mixin link() {
  text-decoration: underline;
  color: map-get($map: $colors, $key: primary);

  &:hover {
    cursor: pointer;
  }
}
