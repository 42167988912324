@mixin box-shadow($withHover: false, $spread: 0) {
  box-shadow: 0px 8px 2em $spread rgba(map-get($map: $colors, $key: copy), 0.2);

  @if $withHover == true {
    transition: box-shadow 0.35s;
    &:hover {
      box-shadow: 0px 8px 2em $spread rgba(map-get($map: $colors, $key: copy), 0.35);
    }
  }
}
