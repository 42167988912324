@mixin scrollX() {
  scroll-behavior: smooth;
  overflow-x: scroll;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0 !important;
    width: 0 !important;
    height: 0 !important;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
}

@mixin scrollY() {
  scroll-behavior: smooth;
  overflow-y: scroll;
  -ms-overflow-style: none;

  &::-webkit-scrollbar {
    width: 0 !important;
    width: 0 !important;
    height: 0 !important;
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: transparent;
  }
}
