@import '../config/';

@mixin type($size: base, $font: copy, $color: copyDark) {
  @include font-size($size);
  font-family: map-get($fonts, $font);
  color: map-get($map: $colors, $key: $color);
}

@mixin copy($font-size: base) {
  @include type($font-size);
  line-height: 1.5;
}

@mixin heading($font-size: large, $font: heading) {
  @include type($font-size, $font);
  line-height: 1.3;
}

@mixin font-size($font-size) {
  @if $font-size ==xtratiny {
    font-size: 0.65rem;
  } @else if $font-size ==tiny {
    font-size: 0.725rem;
  } @else if $font-size ==small {
    font-size: 0.8rem;
  } @else if $font-size ==base {
    font-size: 1rem;
  } @else if $font-size ==medium {
    font-size: 1.25rem;
  } @else if $font-size ==large {
    font-size: 1.5rem;
  } @else if $font-size ==jumbo {
    font-size: 2rem;
  } @else {
    @error "Unknown size #{$font-size}.";
  }

  @include breakpoint(large, min) {
    @if $font-size ==xtratiny {
      font-size: 0.7rem;
    } @else if $font-size ==tiny {
      font-size: 0.8rem;
    } @else if $font-size ==small {
      font-size: 1rem;
    } @else if $font-size ==base {
      font-size: 1.15rem;
    } @else if $font-size ==medium {
      font-size: 1.45rem;
    } @else if $font-size ==large {
      font-size: 1.75rem;
    } @else if $font-size ==jumbo {
      font-size: 2.25rem;
    } @else {
      @error "Unknown size #{$font-size}.";
    }
  }
}
